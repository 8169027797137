<template>
  <div>
    <RoleCard role="school">
      <template #label>Авторизация</template>
      <template #body>
        <div>
          <div class="row mb-l">
            <div class="col-md-8 col-xs-12">
              <p class="mb-s">
                Для того чтобы стать участником НТО, авторизируйся через
                платформу Талант. Если у&nbsp;вас нет аккаунта в&nbsp;Таланте,
                нажми на&nbsp;кнопку ниже, для того чтобы пройти регистрацию
              </p>
              <p>
                Если тебе еше нет 14&nbsp;лет и&nbsp;у&nbsp;вас нет учетной
                записи Талант, то&nbsp;тебе понадобится помощь родителей или
                ответственных лиц, чтобы они разрешили тебе
                <a
                  class="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://talent.kruzhok.org/personal_data_faq"
                  >делиться своими персональными данными</a
                >
                с&nbsp;платформой Талант
              </p>
            </div>
          </div>
          <BaseButton
            tag="a"
            :href="loginLink"
            >Войти в аккаунт Талант</BaseButton
          >
        </div>
      </template>
    </RoleCard>
  </div>
</template>

<script>
import RoleCard from "@/components/registration/RoleCard.vue";

export default {
  name: "RegistrationLogin",
  components: {
    RoleCard,
  },
  metaInfo() {
    return {
      title: "Регистрация - Вход в учетную запись Талант",
    };
  },
  computed: {
    loginLink() {
      let base = `${this.$store.state.clientApiPrefix}/auth/go`;
      if (this.$route.query.next) {
        base += `?next=${encodeURIComponent(this.$route.query.next)}`;
      }
      return base;
    },
  },
  mounted() {
    window.dataLayer?.push({
      event: "nto_auth_with_talant_oauth_suggest",
      eventCategory: "nto_auth_with_talant",
      eventAction: "oauth_suggest",
      inviteType: "no_invite",
    });
  },
};
</script>

<style></style>
